import { Logo } from './Logo';
import styles from './Navigation.module.scss';
import type { NavigationProps } from './Navigation.types';
import { NavigationItems } from './NavigationItems';

export const Navigation = ({
	contactOnClick,
	isClickedContact,
	isClickedService,
	isClickedTariff,
	logoOnClick,
	serviceOnClick,
	tariffOnClick,
}: NavigationProps): JSX.Element => (
	<div className={styles.navigation}>
		<Logo onClick={logoOnClick} />
		<NavigationItems
			contactOnClick={contactOnClick}
			isClickedContact={isClickedContact}
			isClickedService={isClickedService}
			isClickedTariff={isClickedTariff}
			serviceOnClick={serviceOnClick}
			tariffOnClick={tariffOnClick}
		/>
	</div>
);
