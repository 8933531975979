import { Info } from './Info';
import styles from './Infos.module.scss';
import type { InfosProps } from './Infos.types';

export const Infos = ({
	hoverContact,
	hoverService,
	hoverTariff,
	isHoveredContact,
	isHoveredService,
	isHoveredTariff,
}: InfosProps): JSX.Element => (
	<div className={styles.infos}>
		<Info id="service" onMouseOver={hoverService} isHovered={isHoveredService}>
			<h2>Služby</h2>
			<ul>
				<li>Kompletní pneuservis</li>
				<li>Pravidelné servisní prohlídky</li>
				<li>Příprava na STK a její provedení</li>
				<li>
					Mechanické práce <br />
					<ul>
						<li className={styles.smallerFont}>Výměny olejů</li>
						<li className={styles.smallerFont}> Kompletní opravy brzd</li>
						<li className={styles.smallerFont}> Výměna tlumičů, ramen, čepů</li>
						<li className={styles.smallerFont}> Opravy + výměna výfuků</li>
						<li className={styles.smallerFont}> Výměna rozvodů motoru</li>
					</ul>
				</li>
				<li>Geometrie náprav</li>
				<li>Diagnostika motoru</li>
			</ul>
		</Info>
		<Info id="tariff" onMouseOver={hoverTariff} isHovered={isHoveredTariff}>
			<h2>Ceník</h2>
			<ul>
				<li className={styles.bold}>Kompletní přezutí vozu</li>
				<span>od 800 Kč</span>
				<li className={styles.bold}>Nové pneumatiky i protektory</li>
				<span>za internetové ceny</span>
				<li className={styles.bold}>Mechanické práce</li>
				<span>480 Kč/h</span>
			</ul>
		</Info>
		<Info id="contact" onMouseOver={hoverContact} isHovered={isHoveredContact}>
			<h2>Kontakt</h2>
			<p>Jaroslav Svátek</p>
			<p>
				Po-Pá 7:30-16:00<br></br>(mimo uvedené časy dle domluvy)
			</p>
			<a href="tel:+420608307777">608 307 777</a>
			<br></br>
			<a href="https://www.google.com/localservices/prolist?g2lbs=ANTchaNHaTYh6ZqWo2D9DsDwy9o5vsHES5mvJUdBc4x9iu35C2OQqTN8Lp2Jt9tXF_OqqCOHKQj8MvcTxauRyC1lg2orfU_jPL_0NuW-i1234gBCEPAu3OrItqyVD6ohjt-nKB2Etwvk&hl=cs-CZ&gl=cz&cs=1&ssta=1&q=autoservis%20kraslice&oq=autoservis%20kraslice&slp=MgA6HENoTUkwYnJrNzlxdmd3TVY3S3VEQngzX05nTURSAggCYAB62gFDaE5oZFhSdmMyVnlkbWx6SUd0eVlYTnNhV05sU05iQi1abWVzSUNBQ0ZvYkVBQVlBQmdCSWhOaGRYUnZjMlZ5ZG1seklHdHlZWE5zYVdObGtnRUtZMkZ5WDNKbGNHRnBjcW9CVndvSkwyMHZNR1JuWXpOMEVBRXFEaUlLWVhWMGIzTmxjblpwY3lnUk1oOFFBU0lidHBYMVo3dTdNRVlGLVhmV3U3cEdxRllDQ04yOFJEVjcxRXU4TWhjUUFpSVRZWFYwYjNObGNuWnBjeUJyY21GemJHbGpaUZIBhAEKDS9nLzExajdrbXhzNnEKDS9nLzExYjZkcDNsangKDS9nLzExcndyaHR4Y3cKDS9nLzExamt0N3o3eXYKDS9nLzExbjA2d2t4dmwKDS9nLzExYjZkcGZoOGIKDS9nLzExaDlyaHdkOGwKDS9nLzExdjExMnQxOXcSBBICCAESBAoCCAE%3D&src=2&spp=Cg0vZy8xMWo3a214czZxOrQBV2hzUUFCZ0FHQUVpRTJGMWRHOXpaWEoyYVhNZ2EzSmhjMnhwWTJXU0FRcGpZWEpmY21Wd1lXbHltZ0VBcWdGWENna3ZiUzh3Wkdkak0zUVFBU29PSWdwaGRYUnZjMlZ5ZG1sektCRXlIeEFCSWh1MmxmVm51N3N3UmdYNWQ5YTd1a2FvVmdJSTNieEVOWHZVUzd3eUZ4QUNJaE5oZFhSdmMyVnlkbWx6SUd0eVlYTnNhV05s&serdesk=1&lrlstt=1703683305365&ved=2ahUKEwj6zd3v2q-DAxU9h_0HHbaIBaUQvS56BAgaEAE&scp=Cg9nY2lkOmNhcl9yZXBhaXISNhISCS25GnzkwaBHEcbSMsHTRYv5IghLcmFzbGljZSoUDVnH9B0VIXFhBx16hQYeJRsdegcwABoKYXV0b3NlcnZpcyITYXV0b3NlcnZpcyBrcmFzbGljZSoKQXV0b3NlcnZpcw%3D%3D">
				Sadová 1874, 35801 Kraslice
			</a>
		</Info>
	</div>
);
