import { useState } from 'react';
import './App.module.scss';
import { Infos } from './Infos';
import { MainHeader } from './MainHeader';
import { Navigation } from './Navigation';
import { News } from './News';

export const App = (): JSX.Element => {
	const [contactBigger, setContactBigger] = useState(false);
	const [serviceBigger, setServiceBigger] = useState(false);
	const [tariffBigger, setTariffBigger] = useState(false);

	const serviceBiggerHandler = (): void => {
		setContactBigger(false);
		setServiceBigger(true);
		setTariffBigger(false);
	};

	const tariffBiggerHandler = (): void => {
		setContactBigger(false);
		setServiceBigger(false);
		setTariffBigger(true);
	};

	const contactBiggerHandler = (): void => {
		setContactBigger(true);
		setServiceBigger(false);
		setTariffBigger(false);
	};

	const clearStateHandler = (): void => {
		setContactBigger(false);
		setServiceBigger(false);
		setTariffBigger(false);
	};

	return (
		<>
			<Navigation
				contactOnClick={contactBiggerHandler}
				isClickedContact={contactBigger}
				isClickedService={serviceBigger}
				isClickedTariff={tariffBigger}
				logoOnClick={clearStateHandler}
				serviceOnClick={serviceBiggerHandler}
				tariffOnClick={tariffBiggerHandler}
			/>
			<MainHeader />
			<News />
			<Infos
				hoverContact={contactBiggerHandler}
				hoverService={serviceBiggerHandler}
				hoverTariff={tariffBiggerHandler}
				isHoveredContact={contactBigger}
				isHoveredService={serviceBigger}
				isHoveredTariff={tariffBigger}
			/>
		</>
	);
};
