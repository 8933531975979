import { NavigationItem } from './NavigationItem';
import styles from './NavigationItems.module.scss';
import type { NavigationItemsProps } from './NavigationItems.types';

export const NavigationItems = ({
	contactOnClick,
	isClickedContact,
	isClickedService,
	isClickedTariff,
	serviceOnClick,
	tariffOnClick,
}: NavigationItemsProps): JSX.Element => (
	<div className={styles.navigationItems}>
		<NavigationItem href="#service" isClicked={isClickedService} onClick={serviceOnClick}>
			Služby
		</NavigationItem>
		<NavigationItem href="#tariff" isClicked={isClickedTariff} onClick={tariffOnClick}>
			Ceník
		</NavigationItem>
		<NavigationItem href="#contact" isClicked={isClickedContact} onClick={contactOnClick}>
			Kontakt
		</NavigationItem>
	</div>
);

export default NavigationItems;
