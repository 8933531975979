import styles from './News.module.scss';

export const News = (): JSX.Element => (
	<div className={styles.news}>
		{/* <h4>Aktuálně:</h4> */}
		{/* <p>27.12.2023 - 7.1.2024 ZAVŘENO Z DŮVODU NEMOCI</p> */}
		{/* <p>Loňské ceny stále platí. Spolehlivý servis pro vaše vozidlo.</p> */}
		<p>Zimní pneu a protektory VRANÍK naskladněny.</p>
	</div>
);
